import * as React from "react";
import Grid from "@mui/material/Grid";
import QuestionForm from "../features/question/QuestionForm";
import { useCookies } from "react-cookie";

export default function QuestionPage() {
  const [cookies] = useCookies(["contrast"]);

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      sx={{ bgcolor: cookies.contrast? "yellow" : "primary.main", minHeight: "80dvh" }}
    >
      <QuestionForm />
    </Grid>
  );
}
