import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#FDF3E6",
      contrast: "#ffff00",
    },
    secondary: {
      main: "#487D8E",
      contrast: "#e4e40c",
    },
    dark: {
      main: "#1B3649",
    },
    white: {
      main: "#FFFFFF",
    },
    contrast: { main: "#ffff00" },
    alert: {
      main: "#FF8D3A",
    },
    darkGrey: {
      main: "#CFCFCF",
    },
    text: {
      primary: "#1B3649",
      secondary: "#FFFFFF",
      dark: "#242424",
      contrast: "#000000",
    },
    error: {
      main: red.A400,
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "--TextField-brandBorderColor": "#487D8E",
          "--TextField-brandBorderHoverColor": "#487D8E",
          "--TextField-brandBorderFocusedColor": "#487D8E",
          "& label": {
            color: "var(--TextField-brandBorderFocusedColor)",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: "var(--TextField-brandBorderColor)",
        },
        root: {
          [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: "var(--TextField-brandBorderHoverColor)",
          },
          [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: "var(--TextField-brandBorderFocusedColor)",
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          "&::before, &::after": {
            borderBottom: "2px solid var(--TextField-brandBorderColor)",
          },
          "&:hover:not(.Mui-disabled, .Mui-error):before": {
            borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
          },
          "&.Mui-focused:after": {
            borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          "&::before": {
            borderBottom: "2px solid var(--TextField-brandBorderColor)",
          },
          "&:hover:not(.Mui-disabled, .Mui-error):before": {
            borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
          },
          "&.Mui-focused:after": {
            borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
          },
        },
      },
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
    fontFamily: ["Inter", "sans-serif"].join(","),
  },
});

export default theme;
