import * as React from "react";
import Grid from "@mui/material/Grid";
import AdvertiserForm from "../features/advertiser/AdvertiserForm";
import { useCookies } from "react-cookie";

export default function FormPage() {
  const [cookies] = useCookies(["contrast"]);

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      sx={{ bgcolor: cookies.contrast? "yellow" : "primary.main", py: 14, minHeight: "80dvh" }}
    >
      <AdvertiserForm/>
    </Grid>
  );
}
