import React from "react";
import { Grid, Typography, Link } from "@mui/material";
import { Helmet } from "react-helmet";
import { useCookies } from "react-cookie";

export default function ErrorPage() {
  const [cookies] = useCookies(["contrast"]);

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      sx={{ bgcolor: cookies.contrast? "yellow" : "primary.main", py: 14,  minHeight: "80dvh" }}
    >
          <Helmet>
      <title>{"Błąd w reklamacji - Centrum Konsumenckie"}</title>
      <meta
        name="description"
        content="Błąd w reklamacji - Centrum Konsumenckie"
      />
    </Helmet>
      <Grid sx={{textAlign: "center"}} item xs={10} sm={6} md={6} xl={2}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ fontSize: "2rem", fontWeight: 700 }}
        >
         Błąd w reklamacji :D 
        </Typography>
        <Typography
          sx={{ whiteSpace: "pre-line" }}
          variant="body1"
          gutterBottom
        >
          Aplikacja napotkała błąd i nie poradziła sobie z nim.<br/>Spróbuj jeszcze raz lub <Link color="secondary" href="/kontakt" >skontaktuj się z nami</Link>.
        </Typography>
      </Grid>
    </Grid>
  );
}
