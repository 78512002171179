import * as React from "react";
import Grid from "@mui/material/Grid";
import LoginForm from "../../features/user/LoginForm";
import { useCookies } from "react-cookie";


export default function Login() {
  const [cookies] = useCookies(["contrast"]);

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      sx={{ bgcolor: cookies.contrast? "yellow" : "primary.main", minHeight: "80dvh"}}
    >
      <LoginForm/>
    </Grid>
  );
}
