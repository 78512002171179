import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid, Link, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import * as React from "react";
import { Helmet } from "react-helmet";
import { useCookies } from "react-cookie";

let LinkStyle = {
  display: "flex",
  mb: 2,
  textDecoration: "none",
};

export default function AdminMain() {
  const [cookies] = useCookies(["contrast"]);

  return (
    <Grid
      container
      justifyContent="center"
      sx={{ bgcolor: cookies.contrast? "yellow" : "primary.main", minHeight: "80dvh", pt: 4 }}
    >
      <Grid item xs={11} md={7} pt={1} sx={{ my: 4 }}>
        <Helmet>
          <title>Panel Administratora</title>
        </Helmet>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ fontSize: "2rem", fontWeight: 700 }}
        >
          Panel Administratora
        </Typography>
        <Accordion expanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            Moduł Konsumencki
          </AccordionSummary>
          <AccordionDetails>
            {/* <Link color="text.primary" sx={LinkStyle} href="/admin/flow">
              Podgląd scieżki
            </Link> */}
            <Link
              color="text.primary"
              sx={LinkStyle}
              href="/admin/list?table=question"
            >
              Lista Pytań
            </Link>
            <Link
              color="text.primary"
              sx={LinkStyle}
              href="/admin/list?table=question_option"
            >
              Lista Odpowiedzi
            </Link>
            <Link
              color="text.primary"
              sx={LinkStyle}
              href="/admin/list?table=answer"
            >
              Lista Porad
            </Link>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            Moduł Reklamacyjny
          </AccordionSummary>
          <AccordionDetails>
            {/* <Link color="text.primary" sx={LinkStyle} href="/admin/flow">
              Podgląd scieżki
            </Link> */}
            <Link
              color="text.primary"
              sx={LinkStyle}
              href="/admin/list?table=form"
            >
              Lista Formularzy
            </Link>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3-content"
            id="panel3-header"
          >
            Ustawienia ogolne
          </AccordionSummary>
          <AccordionDetails>
            <Link color="text.primary" sx={LinkStyle} href="/admin/list?table=module">
              Konfiguracja Modułów
            </Link>
            <Link color="text.primary" sx={LinkStyle} href="/admin/list?table=sys_dictionary">
              Słownik systemowy
            </Link>
            <Link color="text.primary" sx={LinkStyle} href="/admin/list?table=user">
              Lista użytkowników
            </Link>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
}
