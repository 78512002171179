import React from "react";
import { Typography, Box, Grid } from "@mui/material";
import { LogoIcon } from "../../components/Navbar";
import Logo from "../../public/Logo.png";
import EditorRead from "../../components/editor/EditorRead";

export default function AnswerBody(props) {
  return (
    <div ref={props.refForBody}>
      <table>
        <Box
          sx={{
            "@media print": {
              "&": {
                backgroundImage: `url(${Logo})`,
                p: 3,
              },
            },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontSize: "2rem",
              mb: 1,
              fontWeight: 700,
              "@media print": {
                "&": { overflow: "hidden", height: 0 },
              },
            }}
          >
            Reklamator
          </Typography>
          <Typography
            variant="body2"
            color="dark"
            sx={{
              fontWeight: 400,
              mb: 6,
              "@media print": {
                "&": { overflow: "hidden", height: 0 },
              },
            }}
          >
            Dzięki formularzowi Reklamatora automatycznie wygenerujesz pismo dla
            przedsiębiorcy, które pomoże Ci w reklamacji towaru lub usługi.
          </Typography>
          <Box
            sx={{
              overflow: "hidden",
              height: 0,
              "@media print": {
                "&": {
                  overflow: "visible",
                  height: "auto",
                },
              },
            }}
          >
            <Grid
              container
              justifyContent="space-between"
              sx={{
                pb: 2,
                mb: 4,
                borderBottom: "1px solid #1B3649",
              }}
            >
              <Grid item>
                <Typography
                  sx={{
                    fontSize: "1.125rem",
                    fontWeight: 700,
                    mb: 2,
                  }}
                >
                  {props.form.label}
                </Typography>
                <Typography
                  sx={{
                    width: "150px",
                    fontSize: "0.438rem",
                  }}
                >
                  Reklamacja wygenerowana automatycznie przez formularz dostępny
                  na stronie <u>centrumkonsumenckie.pl</u>
                </Typography>
              </Grid>
              <Grid item>
                <LogoIcon
                  viewBox="0 0 96 34"
                  sx={{
                    filter: "invert(100%)",
                    width: "180px",
                    height: "64px",
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <thead>
            <tr>
              <Typography
                sx={{
                  width: "100%",
                  overflow: "hidden",
                  height: 0,

                  "@media print": {
                    "&": {
                      overflow: "visible",
                      height: "auto",
                    },
                  },
                }}
              ></Typography>
            </tr>
          </thead>
          <tbody
            style={{
              width: "100%",
            }}
          >
            <tr>
              <td>
                {props.form.text && props.form.text[0] == "{" ? (
                  <EditorRead
                    data={JSON.parse(props.form.text)}
                    editorblock="editorjsRead-container"
                    initialFunction={props.formInitialLoad}
                  />
                ) : (
                  <></>
                )}
              </td>
            </tr>
          </tbody>
          <tfoot
            style={{
              overflow: "hidden",
              height: 0,
              "@media print": {
                "&": {
                  pb: 4,
                  textAlign: "center",
                  height: "50px",
                  fontSize: "0.625rem",
                  opacity: 0.8,
                  position: "fixed",
                  bottom: 0,
                  width: "100%",
                  overflow: "visible",
                  height: "auto",
                  display: "block",
                },
              },
            }}
          >
            <tr>
              <td>
                <Typography
                  variant="body2"
                  color="dark"
                  sx={{
                    mt: 4,
                    overflow: "hidden",
                    height: 0,
                    fontWeight: 400,
                    "@media print": {
                      "&": {
                        minHeight: "50px",
                      },
                    },
                  }}
                >
                  Automatyczne odpowiedzi nie stanowią porad prawnych w
                  konkretnej sprawie Użytkownika, a jedynie informację o
                  obowiązujących przepisach. Odpowiedzi te mają charakter
                  wyłącznie informacyjno-pomocniczy.
                </Typography>
              </td>
            </tr>
          </tfoot>
        </Box>
      </table>
    </div>
  );
}
